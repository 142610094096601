import React from "react"
import Header from "../components/header"
import Container from "../components/container"
import OuterContainer from "../components/outerContainer"
import Title from "../components/title"
import Footer from "../components/footer"

export default function About() {
  return (
    <OuterContainer>
      <Header />
      <Title text="About us"/>
      <Container>
        <p>Taogi is a software company developing useful and fun iOS applications.</p>
      </Container>
      <Footer />
    </OuterContainer>
  )
}